<template>
  <div class="microsoft365Review">
    <div class="microsoft365Review__header container">
      <h2 class="microsoft365Review__header-title">Príbehy absolventov</h2>
    </div>
    <div class="microsoft365Review__body">
      <IndexReviewCarousel/>
    </div>
    <div class="microsoft365Review__footer container">
      <ActionButton :link="link"/>
    </div>
  </div>
</template>
<script>
import ActionButton from "@/components/ActionButton.vue";
import IndexReviewCarousel from "@/components/IndexReviewCarousel.vue";
import {mapGetters} from "vuex";

export default {
  props: {
    link: {
      type: String,
      default: '/courses/uvodny-kurz'
    },
  },
  components: {ActionButton, IndexReviewCarousel},
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>