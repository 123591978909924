<template>
  <div
      class="studyBox"
      :class="{'studyBox--noContent': !$slots.default}"
  >
    <slot/>
    <img
        src="../assets/images/microsoft365/microsoft365Study/9.png"
        srcset="../assets/images/microsoft365/microsoft365Study/9.webp 300w"
        width="300"
        height="478"
        alt=""
        loading="lazy"
    >
    <ActionButton :link="link" :registration-link="registrationLink"/>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue";

export default {
  props: {
    link: {
      type: String,
      default: '/courses/uvodny-kurz'
    },
    registrationLink: {
      type: String,
      default: '/registration'
    },
  },
  components: {ActionButton}
}
</script>