<template>
  <MoreList title="Čítajte tiež" :items="items"/>
</template>

<script>
import MoreList from "@/components/MoreList.vue";
import MoreListItem from "@/components/MoreListItem.vue";

export default {
  components: {
    MoreList,
    MoreListItem
  },
  data() {
    return {
      items: []
    }
  },
  props: {
    active: {
      type: String,
      required: false
    }
  },
  created() {
    const data = [
      {
        key: 'ms365',
        title: 'Microsoft 365',
        description: 'Mať všetky dôležité poznámky, dokumenty, prezentácie, kontakty pohromade nie je už len sen.',
        url: '/microsoft365',
        image: 'microsoft365/microsoft365More/microsoft-365',
        isLocalImage: true,
      },
      {
        key: 'msAzure',
        title: 'Microsoft Azure',
        description: 'Dáta sú všade okolo nás a sú v nich uložené informácie, ktoré sú jednou z najcennejších komodít dnešnej doby.',
        url: '/microsoft-azure',
        image: 'microsoft365/microsoft365More/microsoft-azure',
        isLocalImage: true,
      },
      {
        key: 'powerAutomate',
        title: 'Power Automate',
        description: 'Čas je vzácny. Obzvlášť v dnešnej dobe, keď sa všetko okolo nás zrýchľuje. Power Automate ti ho pomôže ušetriť.',
        url: '/power-automate',
        image: 'microsoft365/microsoft365More/power-automate',
        isLocalImage: true,
      },
      {
        key: 'powerBi',
        title: 'Power BI',
        description: 'Držať krok so všetkými novými technológiami je v dnešnej dobe pre mnoho ľudí nevyhnutné...',
        url: '/power-bi',
        image: 'microsoft365/microsoft365More/power-bi',
        isLocalImage: true,
      },
      {
        key: 'cyberSecurity',
        title: 'Cybersecurity',
        description: 'Zvyšování kybernetické bezpečnosti v kritických sektorech se stalo prioritou po celém světě,...',
        url: '/cybersecurity',
        image: 'microsoft365/microsoft365More/cyber-security',
        isLocalImage: true,
      },
    ]

    this.items = data.filter((row) => row.key !== this.active)
  },
}
</script>