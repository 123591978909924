<template>
  <footer class="theFooter">
    <div class="container container--lg">
      <a href="/" class="theFooter__goodcallBrand">
        <img
            src="../assets/images/goodcall-light.svg"
            alt="Goodcall"
            class="icon"
            width="131"
            height="40"
            loading="lazy"
        >
      </a>
      <div class="theFooter__wrapper">
        <div>
          <p class="h3">{{ translation('footer_nav_section-about') }}</p>
          <NavigationList :label="'Secondary'">
            <NavigationListItem
                :url="'/about'"
                :page="'about'"
            >
              {{ translation('footer_nav_link-about') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/blog'"
                :page="'blog'"
            >
              {{ translation('footer_nav_link-articleList') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/studenti'"
                :page="'students'"
            >
              {{ translation('footer_nav_link-students') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/napsali-o-nas'"
                :page="'mentions'"
            >
              {{ translation('footer_nav_link-mentions') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/informacie-o-spracovani-osobnych-udajov'"
                :page="'personalData'"
            >
              {{ translation('footer_nav_link-personal_data') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/politika-ochrany-osobnych-udajov'"
                :page="'privacyPolicy'"
            >
              {{ translation('footer_nav_link-privacy') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/pravidla-sutaze'"
                :page="'competitionRules'"
            >
              {{ translation('footer_nav_link-competition') }}
            </NavigationListItem>
          </NavigationList>
        </div>
        <div>
          <p class="h3">{{ translation('footer_nav_section-microsoft') }}</p>
          <NavigationList :label="'Secondary'">
            <NavigationListItem
                :url="'/microsoft365'"
                :page="'ms365'"
            >
              {{ translation('footer_nav_link-ms365') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/microsoft-azure'"
                :page="'msAzure'"
            >
              {{ translation('footer_nav_link-msAzure') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/power-automate'"
                :page="'powerAutomate'"
            >
              {{ translation('footer_nav_link-powerAutomate') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'/cybersecurity'"
                :page="'cyberSecurity'"
            >
              {{ translation('footer_nav_link-cyberSecurity') }}
            </NavigationListItem>
          </NavigationList>
        </div>
        <div>
          <p class="h3">{{ translation('footer_nav_section-follow') }}</p>
          <NavigationList
              :label="'Links to social sites'"
              class="theFooter__socialIcons"
          >
            <NavigationListItem
                :url="'https://www.facebook.com/ITide.sk/'"
                :title="'Facebook'"
                :openInNewWindow="true"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                <path
                    d="M15.833 0H4.167A4.167 4.167 0 0 0 0 4.167v11.666C0 18.134 1.866 20 4.167 20h11.666A4.167 4.167 0 0 0 20 15.833V4.167C20 1.866 18.135 0 15.833 0Zm-2.5 5.833H11.73c-.513 0-.897.21-.897.741V7.5h2.5l-.198 2.5h-2.302v6.667h-2.5V10H6.667V7.5h1.666V5.897c0-1.684.887-2.564 2.884-2.564h2.116v2.5Z"
                    fill-rule="nonzero"/>
              </svg>
              Facebook
            </NavigationListItem>
            <NavigationListItem
                :url="'https://www.linkedin.com/groups/9063069/'"
                :title="'Linkedin'"
                :openInNewWindow="true"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                <path
                    d="M16.667 15.833h-2.5v-4.67c0-2.806-3.334-2.594-3.334 0v4.67h-2.5V6.667h2.5v1.47c1.164-2.155 5.834-2.314 5.834 2.064v5.632ZM5.417 5.61a1.465 1.465 0 0 1-1.459-1.47c0-.812.654-1.47 1.459-1.47s1.458.658 1.458 1.47c0 .812-.652 1.47-1.458 1.47Zm-1.25 10.223h2.5V6.667h-2.5v9.166ZM15.833 0H4.167A4.167 4.167 0 0 0 0 4.167v11.666A4.167 4.167 0 0 0 4.167 20h11.666A4.167 4.167 0 0 0 20 15.833V4.167A4.167 4.167 0 0 0 15.833 0Z"
                    fill-rule="evenodd"/>
              </svg>
              Linkedin
            </NavigationListItem>
          </NavigationList>
        </div>
        <div>
          <p class="h3">{{ translation('footer_nav_section-contact') }}</p>

          <NavigationList :label="'Contacts'">
            <NavigationListItem
                :url="'mailto:' + translation('global-contact-email')"
                :title="'E-mail'"
            >
              {{ translation('global-contact-email') }}
            </NavigationListItem>
          </NavigationList>
        </div>
      </div>
      <div class="theFooter__brands">
        <a href="https://www.goodcall.sk/" target="_blank" class="theFooter__brands-goodcall">
          <img
              src="../assets/images/goodcall-light.svg"
              alt="Goodcall"
              class="icon"
              width="97"
              height="30"
              loading="lazy"
          >
        </a>

<!--        <div class="icon theFooter__brands-microsoft">-->
<!--          <img-->
<!--              src="../assets/images/sponsoredByMicrosoft-light.svg"-->
<!--              alt="Microsoft"-->
<!--              width="195"-->
<!--              height="24"-->
<!--              loading="lazy"-->
<!--          >-->
<!--        </div>-->
      </div>
    </div>
  </footer>
</template>

<script>
import NavigationListItem from "@/components/NavigationListItem";
import NavigationList from "@/components/NavigationList";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  components: {NavigationList, NavigationListItem},
  mixins: [codeBooksMixin]
}
</script>